import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Edit,
  Create,
  SimpleForm,
  TextInput,
  usePermissions,
  ReferenceField,
  useTranslate
} from "react-admin";
import BackButton from "../../components/BackButton";
import { adminType } from "../../enum/adminType";
import { NoPermission } from "../NoPermission";
import CompanyActions from "./actions/CompanyActions";
import CompanyFilters from "./filters/CompanyFilters";

export const CompanyList = (props) => {
  const {permissions} = usePermissions();
  const translate = useTranslate();

    return (
      <>
      { permissions == adminType.administrator ? 
        (<List perPage={10} actions={<CompanyActions/>} filters={<CompanyFilters />} {...props}>
          <Datagrid rowClick={permissions === adminType.administrator ? "edit" : null}>
            <TextField label={translate("ra.company_field.name")} source="name" />
            <TextField label={translate("ra.company_field.address")} source="address" />
            <TextField label={translate("ra.company_field.city")} source="city" />
            <TextField label={translate("ra.company_field.vat")} source="vatNumber" />
            <ReferenceField label={translate("ra.company_field.user")} source="idUser" reference="users">
              <TextField source="email"/>
            </ReferenceField>
          </Datagrid>
        </List>
        ) : <NoPermission/>
      }
      </>
  );
}

export const CompanyEdit = (props) => {

  const translate = useTranslate();

  return (
    <>
    <Edit title={<CompanyTitle></CompanyTitle>} {...props}>
      <SimpleForm>
        <BackButton variant="outlined" color="secondary" />
        <TextInput label={translate("ra.company_field.name")} source="name" />
        <TextInput label={translate("ra.company_field.address")} source="address" />
        <TextInput label={translate("ra.company_field.city")} source="city" />
        <TextInput label={translate("ra.company_field.vat")} source="vatNumber" />
      </SimpleForm>
    </Edit>
    </>
  );
}

export const CompanyCreate = (props) => {

  const translate = useTranslate();

  return (
    <>
    <Create {...props}>
      <SimpleForm>
        <BackButton variant="outlined" color="secondary" />
        <TextInput label={translate("ra.company_field.name")} source="name" />
        <TextInput label={translate("ra.company_field.address")} source="address" />
        <TextInput label={translate("ra.company_field.city")} source="city" />
        <TextInput label={translate("ra.company_field.vat")} source="vatNumber" />
      </SimpleForm>
    </Create>
    </>
  );
};

// helpers

const CompanyTitle = ({ record }) => {

  const translate = useTranslate();

  return <span>{translate("ra.company_field.entity")} {record ? `"${record.name}"` : ""}</span>;
};
