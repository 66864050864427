import React from "react";
import { UserMenu, MenuItemLink, useTranslate } from "react-admin";
import SettingsIcon from "@material-ui/icons/Settings";

const MyUserMenu = (props) => {

  const translate = useTranslate();

  return (
    <UserMenu {...props}>
      <MenuItemLink
        to="/profile"
        primaryText={translate("ra.profile.my_profile")}
        leftIcon={<SettingsIcon />}
      />
    </UserMenu>
  );
};

export default MyUserMenu;
