import { adminType } from "../enum/adminType";

export default {
  isNotEmpty(obj) {
    try {
      if (obj) {
        return Object.keys(obj).length !== 0;
      }
      return false;
    } catch (err) {
      return false;
    }
  },
  formatTime: (date) => {
    const myDate = new Date(date);
    const formatDate = new Date(date).getTime();
    return formatDate;
  },
  validateEmail: (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  },
  arrayMapIdMongo: (json) => {
    if (json.data) {
      return {
        data: json.data.map((resource) => {
          return { ...resource, id: resource._id };
        }),
        total: json.total ? json.total : 0,
      };
    } else {
      return {
        data: json.map((resource) => {
          return { ...resource, id: resource._id };
        }),
        total: json.total ? json.total : 0,
      };
    }
  },
  singleObjectMapIdMongo: (json) => {
    json.id = json._id;
    delete json._id;
    return {
      data: json,
      total: 2,
    };
  },
  selectEndpoint: (resource, id, reqType, params = null) => {

    const adminLevel = Number(localStorage.getItem('adminLevel'));
    if (resource === "auth") {
      return process.env.REACT_APP_AUTH_URL;
    }
    if (resource === "users") {
      if (reqType === "list") {
        return process.env.REACT_APP_USERS_URL + "/userLevelFilter/" + id;
      }
      if (reqType === "getOne") { 
        if (adminLevel === adminType.administrator) {
          return process.env.REACT_APP_USERS_URL + "/getOneForAdministrator/" + id ;
        }
      }
      if (reqType === "update") {
        if (adminLevel === adminType.administrator) {
          return process.env.REACT_APP_USERS_URL + "/updateForAdministrator/" + id ;
        }
      }
      return process.env.REACT_APP_USERS_URL
    }
    if (resource === "coffeeMachines") {
      if (reqType === "getMany" ) {
        return process.env.REACT_APP_COFFEEMACHINES_URL;
      }
      if (reqType === "getOne") {
        return process.env.REACT_APP_COFFEEMACHINES_URL;
      }
      if (reqType === "list") {
        return process.env.REACT_APP_USERS_URL + "/coffeemachineLevelFilter/" + id;
      }
      if (reqType === "update") {
        if (adminLevel === adminType.producer) {
          return process.env.REACT_APP_COFFEEMACHINES_URL;
        }
        if (adminLevel === adminType.administrator) {
          return process.env.REACT_APP_COFFEEMACHINES_URL + "/uploadFile/" + params.data.serial;
        }
      }
      if ( adminLevel === adminType.producer ) {
        return process.env.REACT_APP_COFFEEMACHINES_URL;
      }
      if ( adminLevel === adminType.endUser || adminLevel === adminType.administrator || adminLevel === adminType.technician ) {
        return process.env.REACT_APP_USER_COFFEE_ASSOCIATIONS_URL + "/coffeemachineLevelFilter/" + id;
      }
    }
    if (resource === "events") {
      if (reqType === "list") {
        return process.env.REACT_APP_USERS_URL + "/eventLevelFilter/" + id;
      }
      if ( adminLevel === adminType.producer || adminLevel === adminType.administrator) {
        return process.env.REACT_APP_EVENTS_URL;
      }
    }
    if (resource === "companies") {
      if (adminLevel === adminType.producer) {
        return process.env.REACT_APP_COMPANIES_URL;
      }
      if (reqType === "list") {
        return process.env.REACT_APP_USERS_URL + "/companiesLevelFilter/" + id;
      } else {
        return process.env.REACT_APP_COMPANIES_URL;
      }
    }
    if (resource === "transaction") {
      if (adminLevel === adminType.producer) {
        return process.env.REACT_APP_TRANSACTION_URL;
      }
      if (reqType === "list") {
        return process.env.REACT_APP_USERS_URL + "/transactionLevelFilter/" + id;
      } else {
        return process.env.REACT_APP_TRANSACTION_URL;
      }
    }
    if (resource === "maintenance") {
      if (reqType === "list") {
        return process.env.REACT_APP_USERS_URL + "/maintenanceLevelFilter/" + id;
      } else {
        return process.env.REACT_APP_MAINTENANCE_URL;
      }
    }
    if (resource === "credit") {
      if (reqType === "list") {
        return process.env.REACT_APP_USERS_URL + "/creditLevelFilter/" + id;
      } else {
        return process.env.REACT_APP_CREDIT_URL;
      }
    }
  },
  getAllIds: (params) => {
    let query = "";
    params.ids.forEach((element, index) => {
      if (index <= params.ids.length - 1) {
        query += element + ",";
      }
    });
    query = query.slice(0, -1);
    console.log("this is query " + query)
    return query;
  },
  isLoginFree: (myPathName) => {
    switch (myPathName) {
      case "/forgot-password":
        return true;
      case "/registration":
        return true;
      case "/registration-maintenance": 
        return true;
      case "/registered":
        return true;
      case "/generate-new-password":
        return true;
      default:
        return false;
    }
  },
};
