import * as React from "react";
import {useTranslate} from 'react-admin';
import { Card, CardContent, CardHeader } from "@material-ui/core";

export default () => {


  const translate = useTranslate()

  return (
  <Card>
    <CardHeader title={translate("ra.dashboard_field.cloud_admin")} />
    <CardContent>{translate("ra.dashboard_field.welcome")}</CardContent>
  </Card>

  )
}
