import React from "react";
import { connect } from "react-redux";
import ApiCalls from "../services/ApiCalls";
import Utils from "../services/Utils";
import {
  useLogin,
  useTranslate,
  useNotify,
  useRedirect,
} from "react-admin";
import {
  Paper,
  withStyles,
  Grid,
  TextField,
  Button,
  Typography,
} from "@material-ui/core";
import { Field, Form } from "react-final-form";
import {
  Fingerprint,
  ArrowBackIos,
  FontDownload,
  Email,
} from "@material-ui/icons";
import { adminType } from "../enum/adminType";
const styles = (theme) => ({
  container: {
    display: "flex",
    height: "100vh",
    justifyContent: "center",
    backgroundColor: "#002e35",
  },
  margin: {
    margin: theme.spacing.unit * 2,
    marginTop: "3vh",
    marginRight: "3vw",
  },
  padding: {
    marginTop: "5vh",
    padding: theme.spacing.unit,
    width: "80vw",
    height: "82vh",
  },
  singleElement: {
    height: "14vh",
    marginRight: "20vw",
  },
  errorStyle: {
    color: "red",
    fontSize: "0.9em",
  },
});

const MyRegistrationPage = (props) => {
  const { classes } = props;
  const login = useLogin();
  const notify = useNotify();
  const translate = useTranslate();
  const redirect = useRedirect();

  const submit = async (values) => {
    // submit validations

    console.log(" submitting ");

    // TODOFrancesco fix validations.
    values.adminLevel = adminType.administrator
    const postUser = await ApiCalls.register(values);
    redirect("/login");
  };

  const validate = (values) => {
    const errors = {};

    // email
    if (!values.email) {
      errors.email = translate("ra.validation.required");
    } else if (values.email) {
      if (Utils.validateEmail(values.email) === false) {
        errors.email = translate("ra.validation.email");
      }
    }

    // password
    if (!values.password) {
      errors.password = translate("ra.validation.required");
    } else if (values.password.length < 8) {
      errors.password = translate("ra.validation.passwordLength");
    }

    // confirm password
    if (!values.confirmPassword) {
      errors.confirmPassword = translate("ra.validation.required");
    } else if (values.confirmPassword !== values.password) {
      errors.confirmPassword = translate("ra.validation.password");
    }

    console.log(" errors list ", errors);

    return errors;
  };

  return (
    <Form
      onSubmit={submit}
      initialValues={{
        email: "",
        name: "",
        surname: "",
        password: "",
        confirmPassword: "",
      }}
      validate={validate}
      render={({ handleSubmit }) => {
        const backSubmit = () => {
          console.log();
          redirect("/login");
        };

        return (
          <div className={classes.container}>
            <Paper className={classes.padding}>
              <div className={classes.margin}>
                <Grid
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "2vh",
                  }}
                  item
                  md={true}
                  sm={true}
                  xs={true}
                >
                  <ArrowBackIos
                    style={{ marginLeft: "1vw" }}
                    onClick={backSubmit}
                  />
                  <Typography
                    align="center"
                    variant="h5"
                    style={{ color: "#3f51b5" }}
                  >
                    {translate('ra.registration.submit')}
                  </Typography>

                  <ArrowBackIos style={{ color: "white" }} />
                </Grid>
                <form onSubmit={handleSubmit}>
                  <Grid
                    className={classes.singleElement}
                    container
                    spacing={6}
                    alignItems="center"
                  >
                    <Grid item>
                      <FontDownload style={{ color: "white" }} />
                    </Grid>
                    <Grid item md={true} sm={true} xs={true}>
                      <Field name="name">
                        {({ input, meta }) => (
                          <TextField
                            {...input}
                            id="name"
                            label={translate('ra.registration.name')}
                            type="text"
                            fullWidth
                          />
                        )}
                      </Field>
                    </Grid>
                  </Grid>
                  <Grid
                    className={classes.singleElement}
                    container
                    spacing={6}
                    alignItems="center"
                  >
                    <Grid item>
                      <FontDownload style={{ color: "white" }} />
                    </Grid>
                    <Grid item md={true} sm={true} xs={true}>
                      <Field name="surname">
                        {({ input, meta }) => {
                          return (
                            <>
                              <TextField
                                {...input}
                                id="surname"
                                label={translate('ra.registration.surname')}
                                type="text"
                                fullWidth
                              />
                            </>
                          );
                        }}
                      </Field>
                    </Grid>
                  </Grid>
                  <Grid
                    className={classes.singleElement}
                    container
                    spacing={6}
                    alignItems="center"
                  >
                    <Grid style={{ paddingBottom: "1.5vh" }} item>
                      <Email />
                    </Grid>
                    <Grid item md={true} sm={true} xs={true}>
                      <Field name="email">
                        {({ input, meta }) => (
                          <>
                            <TextField
                              {...input}
                              id="email"
                              label="Email"
                              type="email"
                              fullWidth
                              required
                            />
                            {meta.touched &&
                              (meta.error || meta.submitError) && (
                                <span className={classes.errorStyle}>
                                  {meta.error || meta.submitError}
                                </span>
                              )}
                          </>
                        )}
                      </Field>
                    </Grid>
                  </Grid>

                  <Grid
                    className={classes.singleElement}
                    container
                    spacing={6}
                    alignItems="center"
                  >
                    <Grid style={{ paddingBottom: "1.5vh" }} item>
                      <Fingerprint />
                    </Grid>
                    <Grid item md={true} sm={true} xs={true}>
                      <Field name="password">
                        {({ input, meta }) => {
                          return (
                            <>
                              <TextField
                                {...input}
                                id="password"
                                label="Password"
                                type="password"
                                fullWidth
                                required
                              />
                              {meta.touched &&
                                (meta.error || meta.submitError) && (
                                  <span className={classes.errorStyle}>
                                    {meta.error || meta.submitError}
                                  </span>
                                )}
                            </>
                          );
                        }}
                      </Field>
                    </Grid>
                  </Grid>
                  <Grid
                    className={classes.singleElement}
                    container
                    spacing={6}
                    alignItems="center"
                  >
                    <Grid item>
                      <Fingerprint style={{ color: "white" }} />
                    </Grid>
                    <Grid item md={true} sm={true} xs={true}>
                      <Field name="confirmPassword">
                        {({ input, meta }) => (
                          <>
                            <TextField
                              {...input}
                              id="confirmPassword"
                              label={translate('ra.registration.confirm_password')}
                              type="password"
                              fullWidth
                              required
                            />
                            {meta.touched &&
                              (meta.error || meta.submitError) && (
                                <span className={classes.errorStyle}>
                                  {meta.error || meta.submitError}
                                </span>
                              )}
                          </>
                        )}
                      </Field>
                    </Grid>
                  </Grid>
                  {/* <Grid
                    className={classes.singleElement}
                    container
                    spacing={6}
                    alignItems="center"
                  >
                    <Grid style={{ paddingBottom: "1.5vh" }} item>
                      <Business />
                    </Grid>
                    <Grid item md={true} sm={true} xs={true}>
                      <Field name="company">
                        {({ input, meta }) => (
                          <>
                            <TextField
                              {...input}
                              id="company"
                              label="Nome Azienda"
                              type="text"
                              fullWidth
                              required
                            />
                            {meta.touched &&
                              (meta.error || meta.submitError) && (
                                <span className={classes.errorStyle}>
                                  {meta.error || meta.submitError}
                                </span>
                              )}
                          </>
                        )}
                      </Field>
                    </Grid>
                  </Grid>
                  <Grid
                    className={classes.singleElement}
                    container
                    spacing={6}
                    alignItems="center"
                  >
                    <Grid item>
                      <FontDownload style={{ color: "white" }} />
                    </Grid>
                    <Grid item md={true} sm={true} xs={true}>
                      <Field name="vatNumber">
                        {({ input, meta }) => (
                          <>
                            <TextField
                              {...input}
                              id="vatNumber"
                              label="Partita IVA"
                              type="text"
                              fullWidth
                              required
                            />
                            {meta.touched &&
                              (meta.error || meta.submitError) && (
                                <span className={classes.errorStyle}>
                                  {meta.error || meta.submitError}
                                </span>
                              )}
                          </>
                        )}
                      </Field>
                    </Grid>
                  </Grid> */}
                  <Grid
                    style={{ marginTop: "8vh" }}
                    container
                    alignItems="center"
                    justify="space-between"
                  ></Grid>
                  <Grid container justify="center" style={{ marginTop: "3vh" }}>
                    <Button
                      variant="outlined"
                      color="primary"
                      style={{ textTransform: "none" }}
                      type="submit"
                    >
                      Invia
                    </Button>
                  </Grid>
                </form>
              </div>
            </Paper>
          </div>
        );
      }}
    />
  );
};

export default withStyles(styles)(MyRegistrationPage);
