import * as React from "react";
import {
  List,
  Datagrid,
  NumberField,
  TextField,
  ReferenceField,
  useTranslate,
  DateField,
} from "react-admin";
import TransactionActions from "./actions/TransactionActions";
import { Empty } from "../Empty";
import TransactionFilters from "./filters/TransactionFilters";

export const TransactionList = (props) => {

  const translate = useTranslate();
  
  return(
    <List empty={<Empty/>} filters={<TransactionFilters/>} actions={<TransactionActions/>} perPage={10} {...props}>
      <Datagrid >
        <ReferenceField label={translate('ra.transaction_field.sender')} link="" source="sender" reference="users">
          <TextField source="email"/>
        </ReferenceField>
        <ReferenceField label={translate('ra.transaction_field.manager')} source="idAdmin" reference="users">
          <TextField source="email"/>
        </ReferenceField>
        <ReferenceField label={translate('ra.transaction_field.user_receiver')} link="" source="receiver" reference="users">
          <TextField source="email"/>
        </ReferenceField>
        <ReferenceField label={translate('ra.transaction_field.machine_receiver')} link="" source="receiverMachine" reference="coffeeMachines">
          <TextField disabled source="serial"/>
        </ReferenceField>
        <NumberField label={translate('ra.transaction_field.quantity')} source="quantity" />
        <DateField label={translate('ra.transaction_field.date')} source="date" />
      </Datagrid>
    </List>
  );
}

// helpers

const TransactionTitle = ({ record }) => {
  return <span>Transazione {record ? `"${record.email}"` : ""}</span>;
};
