import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  SelectField,
  DateField,
  useTranslate,
} from "react-admin";
import { nfcError } from "../../enum/eventError";
import { Empty } from "../Empty";
import EventFilters from "./filters/EventFilters";


export const EventList = (props) => {

  const translate = useTranslate();

  return(
  <List empty={<Empty/>} perPage={10} filters={<EventFilters />} {...props}>
    <Datagrid>
      <TextField label={translate("ra.event_field.serial")} source="serial" />
      <SelectField label={translate("ra.event_field.code")} choices={nfcError} source="codeType" />
      <DateField label={translate("ra.event_field.event_date")} source="eventDate" />
    </Datagrid>
  </List>
  )
};

// export const EventEdit = (props) => (
//   <Edit title={<EventTitle></EventTitle>} {...props}>
//     <SimpleForm>
//       <BackButton variant="outlined" color="secondary" />
//       <ReferenceInput
//         label="Macchina del caffè"
//         source="idCoffeeMachine"
//         reference="coffeeMachines"
//       >
//         <SelectInput optionText="serial" />
//       </ReferenceInput>
//       <SelectInput label="Codici" choices={nfcError} source="codeType" />
//       <DateInput label="Data Evento" source="eventDate" />
//     </SimpleForm>
//   </Edit>
// );

// export const EventCreate = (props) => {
//   return (
//     <Create {...props}>
//       <SimpleForm toolbar={<EventCreateToolbar />}>
//         <BackButton variant="outlined" color="secondary" />
//         <ReferenceInput
//           label="Macchina del caffè"
//           source="idCoffeeMachine"
//           reference="coffeeMachines"
//         >
//           <SelectInput optionText="serial" />
//         </ReferenceInput>
//         <SelectInput label="Codice" choices={nfcError} source="codeType" />
//       </SimpleForm>
//     </Create>
//   );
// };

// helpers

const EventTitle = ({ record }) => {

  const translate = useTranslate()

  return <span>{translate("ra.event_field.entity")} {record ? `"${record.eventDate}"` : ""}</span>;
};
