import React from "react";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import ArrowBack from "@material-ui/icons/ArrowBack";
import { withRouter } from "react-router";
import { makeStyles } from "@material-ui/core/styles";

const BackButton = ({ history: { goBack }, children, ...props }) => {
  return (
    <IconButton
      onClick={goBack}
      style={{ marginBottom: 20 }}
      aria-label="arrow-back"
    >
      <ArrowBack />
    </IconButton>
  );
};

export default withRouter(BackButton);
