import * as React from "react";
import {
  List,
  PasswordInput,
  Datagrid,
  RadioButtonGroupInput,
  TextField,
  EmailField,
  Edit,
  Create,
  Pagination,
  NumberInput,
  SimpleForm,
  TextInput,
  NumberField,
  FunctionField,
  useTranslate,
  usePermissions
} from "react-admin";
import BackButton from "../../components/BackButton";
import { adminType } from "../../enum/adminType";
import { Empty } from "../Empty";
import UserActions from "./actions/UserActions";
import UsersFilters from "./filters/UsersFilters";
import UsersCreateToolbar from "./toolbars/UsersCreateToolbar";

export const UsersList = (props) => {

  const translate = useTranslate();
  const {permissions} = usePermissions();
  return(
    <List empty={<Empty/>} filters={<UsersFilters/>} perPage={500} {...props}>
      <Datagrid rowClick={permissions === adminType.producer || permissions === adminType.administrator ? "edit" : null}>
        <TextField label={translate("ra.user_field.name")} source="name" />
        <TextField label={translate("ra.user_field.surname")} source="surname" />
        <EmailField label={translate("ra.user_field.email")} source="email" />
        <FunctionField
          label={translate("ra.user_field.admin_level")}
          source="adminLevel"
          render={(record) => {
            if (record) {
              if (record.adminLevel === adminType.endUser) {
                return  translate("ra.users.user");
              }
              if (record.adminLevel === adminType.administrator) {
                return  translate("ra.users.admin");
              }
              if (record.adminLevel === adminType.producer) {
                return  translate("ra.users.producer");
              }
              if (record.adminLevel === adminType.technician) {
                return  translate("ra.users.technician");
              }
            }
          }}
        />
        {permissions === adminType.administrator && 
         <NumberField min={0} label={translate("ra.user_field.credit")} source="credit" /> 
        }
      </Datagrid>
    </List>
  );
}

export const UsersEdit = (props) => {

  const translate = useTranslate();
  const { permissions} = usePermissions();

  return (
    <>
    <Edit title={<UsersTitle></UsersTitle>} {...props}>
      <SimpleForm>
        <BackButton variant="outlined" color="secondary" />
        <TextInput label={translate("ra.user_field.name")} source="name" />
        <TextInput label={translate("ra.user_field.surname")} source="surname" />
        <TextInput label={translate("ra.user_field.email")} source="email" />
        {permissions === adminType.producer && 
        <RadioButtonGroupInput
          label="Livello amministrativo"
          source="adminLevel"
          choices={[
            { id: 0, name: translate("ra.users.user") },
            { id: 1, name: translate("ra.users.admin") },
            { id: 2, name: translate("ra.users.producer") },
            { id: 3, name: translate("ra.users.technician") }
          ]}
        />
        }
        {permissions === adminType.administrator &&
          <NumberInput min={0} label={translate("ra.user_field.credit")} source="credit" /> 
        }
      </SimpleForm>
    </Edit>
  </>
  );
}

export const UsersCreate = ({ ...props }) => {

  const translate = useTranslate();

  return (
    <Create {...props}>
      <SimpleForm toolbar={<UsersCreateToolbar />}>
        <BackButton variant="outlined" color="secondary" />
        <TextInput label="Nome" source="name" />
        <TextInput label="Cognome" source="surname" />
        <TextInput label="Email" source="email" />
        <PasswordInput label="Password" source="password" />
        <RadioButtonGroupInput
          label="Livello amministrativo"
          source="adminLevel"
          choices={[
            { id: 0, name: translate("ra.users.user") },
            { id: 1, name: translate("ra.users.admin") },
            { id: 2, name: translate("ra.users.producer") },
            { id: 3, name: translate("ra.users.technician") }
          ]}
        />
        <NumberInput min={0} label="Credito" source="credit" /> 
      </SimpleForm>
    </Create>
  );
};

// helpers

const UsersTitle = ({ record }) => {
  const translate = useTranslate();
  return <span>{translate("ra.users.user")} {record ? `"${record.email}"` : ""}</span>;
};
