import React from "react";
import { Admin, Resource, usePermissions, useTranslate } from "react-admin";
import { Route } from "react-router-dom";
import { UsersCreate, UsersEdit, UsersList } from "./entities/Users/Users";
import UserIcon from "@material-ui/icons/Group";
import LocalCafeIcon from "@material-ui/icons/LocalCafe";
import BuildIcon from '@material-ui/icons/Build';
import HistoryIcon from '@material-ui/icons/History';
import CompanyIcon from "@material-ui/icons/Business";
import EventIcon from "@material-ui/icons/Event";
import AuthProvider from "./AuthProvider";
import DataProvider from "./DataProvider";
import i18nProvider from "./services/i18n";
import MyLoginPage from "./pages/MyLoginPage";
import MyRegistrationPage from "./pages/MyRegistrationPage";
import MyForgotPasswordPage from "./pages/MyForgotPasswordPage";
import {
  CoffeemachineEdit,
  CoffeemachineList,
} from "./entities/Coffeemachine/Coffeemachine";
import ReactNotification from "react-notifications-component";
import MyGeneratePasswordPage from "./pages/MyGeneratePasswordPage";
import MyRegisteredPage from "./pages/MyRegisteredPage";
import { EventList } from "./entities/Event/Event";
import MyDashboard from "./pages/MyDashboard";
import { ProfileEdit } from "./profile/profile";
import MyLayout from "./components/MyLayout";
import { CompanyCreate, CompanyEdit, CompanyList } from "./entities/Companies/Companies";
import { TransactionList } from "./entities/Transaction/Transaction";
import { MaintenanceList } from "./entities/Maintenance/Maintenance";
import { adminType } from "./enum/adminType";
import MyRegistrationMaintenancePage from "./pages/MyRegistrationMaintenancePage";

const App = () => {

  const translate = useTranslate();

  return (
    <>
      <ReactNotification />
      <Admin
        loginPage={MyLoginPage}
        dashboard={MyDashboard}
        appLayout={MyLayout}
        authProvider={AuthProvider}
        dataProvider={DataProvider}
        i18nProvider={i18nProvider}
        customRoutes={[
          <Route
            path="/generate-new-password/:token"
            component={MyGeneratePasswordPage}
            noLayout
          />,
          <Route
            path="/registered/:token"
            component={MyRegisteredPage}
            noLayout
          />,
          <Route key="profile" path="/profile" component={ProfileEdit} />,
          <Route
            path="/registration"
            component={MyRegistrationPage}
            noLayout
          />,
          <Route
          path="/registration-maintenance"
          component={MyRegistrationMaintenancePage}
          noLayout
          />,
          <Route
            path="/forgot-password"
            component={MyForgotPasswordPage}
            noLayout
          />,
        ]}
      >
        <Resource
          name="users"
          list={UsersList}
          edit={UsersEdit}
          create={UsersCreate}
          icon={UserIcon}
          options={{ label: translate("ra.entity.users") }}
        />
        {/* <Resource
          name="credit"
          list={CreditList}
          edit={CreditEdit}
          create={CreditCreate}
          icon={PaymentIcon}
          options={{ label: translate("ra.entity.credits") }}
        /> */}
        <Resource
          name="companies"
          list={CompanyList}
          edit={CompanyEdit}
          create={CompanyCreate}
          icon={CompanyIcon}
          options={{ label: translate("ra.entity.companies") }}
        />
        <Resource
          name="coffeeMachines"
          list={CoffeemachineList}
          edit={CoffeemachineEdit}
          // create={CoffeemachineCreate}
          icon={LocalCafeIcon}
          options={{ label: translate("ra.entity.coffeemachine") }}
        />
        <Resource
          name="events"
          list={EventList}
          // edit={EventEdit}
          // create={EventCreate}
          icon={EventIcon}
          options={{ label: translate("ra.entity.events") }}
        />
        <Resource
          name="transaction"
          list={TransactionList}
          icon={HistoryIcon}
          options={{ label: translate("ra.entity.transaction") }}
        />
        <Resource
          name="maintenance"
          list={MaintenanceList}
          icon={BuildIcon}
          options={{ label: translate("ra.entity.maintenance") }}
        />
        <Resource name="profile" />
      </Admin>
    </>
  );
};

export default App;
