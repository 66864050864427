import React, { useEffect, useState } from "react";
import ApiCalls from "../services/ApiCalls";
import { useParams } from "react-router-dom";
import { useTranslate, useRedirect, useNotify } from "react-admin";
import { Fingerprint } from "@material-ui/icons";
import {
  Paper,
  withStyles,
  Grid,
  Button,
  TextField,
  Typography,
} from "@material-ui/core";
import { ArrowBackIos } from "@material-ui/icons";
import { Field, Form } from "react-final-form";
import { rangeRight } from "lodash-es";
const styles = (theme) => ({
  container: {
    display: "flex",
    height: "100vh",
    justifyContent: "center",
    backgroundColor: "#002e35",
  },
  margin: {
    margin: theme.spacing.unit * 2,
    marginTop: "3vh",
    marginRight: "3vw",
  },
  padding: {
    marginTop: "10vw",
    padding: theme.spacing.unit,
    width: "80vw",
    height: "70vh",
  },
  errorStyle: {
    color: "red",
    fontSize: "0.9em",
  },
});

const MyGeneratePasswordPage = (props) => {
  const { classes } = props;
  const translate = useTranslate();
  const redirect = useRedirect();
  const { token } = useParams();
  const [isEnabled, setisEnabled] = useState(false);
  const notify = useNotify();

  useEffect(async () => {
    const didForget = await ApiCalls.isPasswordTokenEnabled(token);
    setisEnabled(didForget);
  }, []);

  const submit = async (values) => {
    values.token = token;
    const changedPassword = await ApiCalls.changePassword(values);
    notify(`Change password approved`, { type: 'success' });
    redirect("/login");
  };

  const validate = (values) => {
    const errors = {};

    // password
    if (!values.password) {
      errors.password = translate("ra.validation.required");
    } else if (values.password.length < 8) {
      errors.password = translate("ra.validation.passwordLength");
    }

    // confirm password
    if (!values.confirmPassword) {
      errors.confirmPassword = translate("ra.validation.required");
    } else if (values.confirmPassword !== values.password) {
      errors.confirmPassword = translate("ra.validation.password");
    }

    return errors;
  };

  function isForget(handleSubmit) {
    if (isEnabled) {
      return (
        <form onSubmit={handleSubmit}>
          <Grid
            className={classes.singleElement}
            container
            spacing={6}
            alignItems="center"
          >
            <Grid style={{ paddingBottom: "1.5vh" }} item>
              <Fingerprint />
            </Grid>
            <Grid item md={true} sm={true} xs={true}>
              <Field name="password">
                {({ input, meta }) => {
                  return (
                    <>
                      <TextField
                        {...input}
                        id="password"
                        label="Password"
                        type="password"
                        fullWidth
                        required
                      />
                      {meta.touched && (meta.error || meta.submitError) && (
                        <span className={classes.errorStyle}>
                          {meta.error || meta.submitError}
                        </span>
                      )}
                    </>
                  );
                }}
              </Field>
            </Grid>
          </Grid>
          <Grid
            className={classes.singleElement}
            container
            spacing={6}
            alignItems="center"
          >
            <Grid item>
              <Fingerprint style={{ color: "white" }} />
            </Grid>
            <Grid item md={true} sm={true} xs={true}>
              <Field name="confirmPassword">
                {({ input, meta }) => (
                  <>
                    <TextField
                      {...input}
                      id="confirmPassword"
                      label="Conferma Password"
                      type="password"
                      fullWidth
                      required
                    />
                    {meta.touched && (meta.error || meta.submitError) && (
                      <span className={classes.errorStyle}>
                        {meta.error || meta.submitError}
                      </span>
                    )}
                  </>
                )}
              </Field>
            </Grid>
          </Grid>
          <Grid container justify="center" style={{ marginTop: "5vh" }}>
            <Button
              variant="outlined"
              color="primary"
              style={{ textTransform: "none" }}
              type="submit"
            >
              Invia
            </Button>
          </Grid>
        </form>
      );
    } else {
      return (
        <Grid style={{ marginTop: "3vh" }} item md={true} sm={true} xs={true}>
          <Typography style={{ color: "#3f51b5", textAlign: "center" }}>
            il token utilizzato per la rigenerazione della password non è valido
          </Typography>
        </Grid>
      );
    }
  }

  return (
    <Form
      onSubmit={submit}
      initialValues={{
        password: "",
        confirmPassword: "",
      }}
      validate={validate}
      render={({ handleSubmit }) => {
        const backSubmit = () => {
          console.log();
          redirect("/login");
        };

        return (
          <div className={classes.container}>
            <Paper className={classes.padding}>
              <div className={classes.margin}>
                <Grid
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "2vh",
                  }}
                  item
                  md={true}
                  sm={true}
                  xs={true}
                >
                  <ArrowBackIos
                    style={{ marginLeft: "1vw" }}
                    onClick={backSubmit}
                  />
                  <Typography
                    align="center"
                    variant="h5"
                    style={{ color: "#3f51b5" }}
                  >
                    Genera password
                  </Typography>

                  <ArrowBackIos style={{ color: "white" }} />
                </Grid>
                {isForget(handleSubmit)}
              </div>
            </Paper>
          </div>
        );
      }}
    />
  );
};

export default withStyles(styles)(MyGeneratePasswordPage);
