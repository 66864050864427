import { fetchUtils } from "react-admin";
import { stringify } from "query-string";
import Utils from "./services/Utils";
import _ from "lodash";
import ApiCalls from "./services/ApiCalls";
import {adminType} from './enum/adminType';

const endpointUrl = "";

const httpClient = (url, data = "GET", body = null) => {

  let method;
  let requestBody;
  // elaborate method for httpclient
  if (data && !data.method) {
    method = data;
    requestBody = body;
  }
  if (data && data.method) {
    method = data.method
    requestBody = data.body
  }
  if (!data) {
    method = "GET"
  }

  // elaborate options
  const options = {
    body: requestBody,
    method: method,
    headers: data && data.headers ? new Headers({ Accept: data.headers}) : new Headers({ Accept: "application/json" }),
  };
  const token = localStorage.getItem('accessToken')
  console.log("token is " + token);
  if (token) {
    options.headers.set("Authorization", `Bearer ${token}`);
  }

  return fetchUtils.fetchJson(url, options);
};

export default {
  // profile

  updateUserProfile: async (params) => {
    const id = localStorage.getItem('id')
    const res = await ApiCalls.updateUser(id, params);
    const val = {
      data: res,
    };
    return val;
  },

  // normal API calls

  getList: (apiUrl, resource, params) => {
    const { page, perPage } = resource.pagination;
    const query = {
      sort: JSON.stringify(resource.sort),
      start: JSON.stringify((page - 1) * perPage),
      end: JSON.stringify(page * perPage - 1),
      filter: JSON.stringify(resource.filter),
    };
    const id = localStorage.getItem('id');
    const reqType = "list";
    const endpoint = Utils.selectEndpoint(apiUrl, id, reqType);
    const url = `${endpoint}?${stringify(query)}`;
    console.log( " doing get list ... " );
    return httpClient(url).then(({ headers, json }) => {
      console.log( " this is json " + JSON.stringify(json));
      return Utils.arrayMapIdMongo(json);
    });
  },

  getOne: (resource, params) => {
    const reqType = "getOne";

    const id = localStorage.getItem('id');
    const endpoint = Utils.selectEndpoint(resource, id, reqType);
    return httpClient(`${endpoint}/${params.id}`).then(({ json }) => {
      return Utils.singleObjectMapIdMongo(json);
    });
  },

  getMany: (resource, params) => {
    const query = Utils.getAllIds(params);
    const reqType = "getMany";
    console.log( " doing get many ... " );
    const endpoint = Utils.selectEndpoint(resource, null, reqType);
    const url = `${endpoint}/many?id=${query}`;
    return httpClient(url).then(({ json }) => {
      return Utils.arrayMapIdMongo(json);
    });   
  },

  // TODOFrancesco : check getManyReference
  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    console.log( " doing get many reference... " );
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${endpointUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => {
      // ({
      //   data: json,
      //   total: parseInt(headers.get("content-range").split("/").pop(), 10),
      // })
      return Utils.arrayMapIdMongo(json);
    });
  },

  update: (resource, params) => {
    const reqType = "update";
    const idAdmin = localStorage.getItem('id');
    const adminLevel = Number(localStorage.getItem('adminLevel'));
    const endpoint = Utils.selectEndpoint(resource, idAdmin, reqType, params);

    if (resource === "coffeeMachines" && adminLevel === adminType.administrator) {
      let formData = new FormData();
      
      if (params.data.document && params.data.document.rawFile) {
        formData.append('document', params.data.document.rawFile);
      } else {
      }
      formData.append('idCompany', params.data.idCompany);
      formData.append('credits', params.data.credit);
      debugger;
      formData.append('workingTemperature', params.data.temperature);

      console.log(" endpoint is : " + endpoint);

      return httpClient(`${endpoint}`,{
        method: "POST",
        body: formData
      }).then(({ json }) => {
        return Utils.singleObjectMapIdMongo(json);
      }).catch((err) => {
        console.log(err);
      })
    } else {
      
      let diff = Object.keys(params.data).reduce((diff, key) => {
        if (params.data[key] === params.previousData[key])  return diff;
        if (params.data[key] !== "") {
          if (key == "warrantyDate") {
            const isDate = new Date(params.data[key])
            if (isNaN(isDate) === false || isDate === 0) {
              params.data[key] = Utils.formatTime(params.data[key]);
            }
            return {
              ...diff,
              [key]: params.data[key],
            };
          } else {
            return {
              ...diff,
              [key]: params.data[key],
            };
          }
        }
      }, {});
      return httpClient(
        `${endpoint}/${params.id}`,
        {
          method: "PATCH",
          body: JSON.stringify(diff)
        }
      ).then(({ json }) => {
        return Utils.singleObjectMapIdMongo(json);
      });
    }
  },

  updateMany: (resource, params) => {
    const query = Utils.getAllIds(params);
    const endpoint = Utils.selectEndpoint(resource);
    return httpClient(
      `${endpoint}?${query}`,
      "PATCH",
      JSON.stringify(params.data)
    ).then(({ json }) => {
      return Utils.arrayMapIdMongo(json);
    });
  },

  create: (resource, params) => {
    if (resource === "companies") {
      params.data.idUser = localStorage.getItem('id');
    }
    const endpoint = Utils.selectEndpoint(resource);
    return httpClient(`${endpoint}`, "POST", JSON.stringify(params.data)).then(
      ({ json }) => {
        return Utils.singleObjectMapIdMongo(json)
      }
    );
  },

  delete: (resource, params) => {
    const endpoint = Utils.selectEndpoint(resource);
    return httpClient(`${endpoint}/${params.id}`, "DELETE").then(({ json }) => {
      return Utils.singleObjectMapIdMongo(json);
    });
  },

  deleteMany: (resource, params) => {
    const endpoint = Utils.selectEndpoint(resource);
    const query = Utils.getAllIds(params);
    return httpClient(`${endpoint}/many?id=${query}`, "DELETE").then(
      ({ json }) => {
        return Utils.arrayMapIdMongo(json);
      }
    );
  },
};
