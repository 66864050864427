import React, { cloneElement, useMemo } from 'react';
import {
    TopToolbar, CreateButton, ExportButton, Button, sanitizeListRestProps, usePermissions,
} from 'react-admin';
import { adminType } from '../../../enum/adminType';

const MaintenanceActions = ({
    currentSort,
    className,
    resource,
    filters,
    displayedFilters,
    exporter, // you can hide ExportButton if exporter = (null || false)
    filterValues,
    permanentFilter,
    hasCreate, // you can hide CreateButton if hasCreate = false
    basePath,
    selectedIds,
    onUnselectItems,
    showFilter,
    maxResults,
    total,
    ...rest
}) => {

    const {permissions} = usePermissions();

    return(
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                filter={{ ...filterValues, ...permanentFilter }}
                exporter={exporter}
                maxResults={maxResults}
            />
        </TopToolbar>
    );
}

MaintenanceActions.defaultProps = {
    selectedIds: [],
    onUnselectItems: () => null,
};

export default MaintenanceActions;