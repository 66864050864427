import { TextInput, useTranslate, SelectInput, DateInput, Filter } from "react-admin";

const EventFilters = (props) => {

  const translate = useTranslate();

  return (
    <Filter {...props}>
      <TextInput label={translate('ra.event_field.serial')} source="serial" />
      {/* <SelectInput label={translate('ra.event_field.code')} choices={nfcError} source="codeType" /> */}
    </Filter>
  );
};

export default EventFilters;
