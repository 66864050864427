import { TextInput, Filter, useTranslate } from "react-admin";

const MaintenanceFilters = (props) => {

  const translate = useTranslate();

  return (
    <Filter {...props}>
      <TextInput label={translate('ra.maintenance_field.serial')} source="serial" />
    </Filter>
  );
};

export default MaintenanceFilters;
