import polyglotI18nProvider from "ra-i18n-polyglot";
import {
  resolveBrowserLocale,
} from 'react-admin';

// english messages

const englishMessages = {
  ra: {
    empty: "No elements available",
    nopermission: "You don't have the permission to see this",
    entity: {
      users: "Users",
      coffeemachine: "Coffeemachines",
      events: "Events",
      companies: "Companies",
      transaction: "Transactions",
      credits: "Credits",
      maintenance: "Maintenance"
    },
    registration: {
      submit: "Register",
      name: "Name",
      surname: "Surname",
      confirm_password: "Confirm password",
      users: "Users"
    },
    users: {
      producer: "Producer",
      admin: "Administrator",
      technician: "Technician",
      user: "User",
    },
    user_field: {
      name: "Name",
      surname: "Surname",
      email: "Email",
      admin_level: "Administrator level",
      credit: "Credit",
    },
    company_field: {
      entity: "Company",
      name: "Name",
      address: "Address",
      city: "City",
      admin_level: "Administration level",
      vat: "VAT",
      user: "User"
    },
    coffeemachine_field: {
      serial: "Serial",
      sell_doc: "Selling document",
      warranty_date: "Warranty date",
      temperature: "Temperature",
      erogation_number: "Erogation number",
      drawer_limit: "Drawer limit",
      worn_capsules: "Worn capsules",
      descaling_limit: "Descaling limit",
      manager: "Manager",
      customer: "Customer",
      erogation_default: "Default erogation",
      eco_mode: "Eco mode",
      credit: "Credits",
      document: "Document",
      last_update_date: "Update date",
      creation_date: "Creation date",
      size_short: "Short",
      size_medium: "Medium",
      size_long: "Long",
      entity: "Coffeemachine"
    },
    event_field: {
      serial: "Serial",
      code: "Code",
      event_date: "Event date",
      entity: "Event"
    },
    transaction_field: {
      sender: "Sender",
      manager: "Manager",
      user_receiver: "User receiver",
      machine_receiver: "Machine receiver",
      quantity: "Quantity",
      date: "Date",
    },
    maintenance_field: {
      serial: "Serial",
      sweeter_value: "Softener value",
      sweeter_check: "Softener check",
      descaling_value: "Descaling value",
      descaling_check: "Descaling check",
      date: "Date",
    },
    dashboard_field: {
      cloud_admin: "Cloud administration",
      welcome: "Welcome to the administrative page of Caffe 25"
    },
    login: {
      forgot: "Forgot password?",
    },
    forgot: {
      title: "Reset password",
      disclaimer: "An email will be sent with the link to reset password",
    },
    action: {
      add_filter: "Add filter",
      add: "Add",
      back: "Go Back",
      bulk_actions: "1 item selected |||| %{smart_count} items selected",
      cancel: "Cancel",
      clear_input_value: "Clear value",
      clone: "Clone",
      confirm: "Confirm",
      create: "Create",
      create_item: "Create %{item}",
      delete: "Delete",
      edit: "Edit",
      export: "Export",
      list: "List",
      refresh: "Refresh",
      remove_filter: "Remove this filter",
      remove: "Remove",
      save: "Save",
      search: "Search",
      show: "Show",
      sort: "Sort",
      undo: "Undo",
      unselect: "Unselect",
      expand: "Expand",
      close: "Close",
      open_menu: "Open menu",
      close_menu: "Close menu",
      update: "Update",
    },
    boolean: {
      true: "Yes",
      false: "No",
      null: " ",
    },
    page: {
      create: "Create %{name}",
      dashboard: "Dashboard",
      edit: "%{name} #%{id}",
      error: "Something went wrong",
      list: "%{name}",
      loading: "Loading",
      not_found: "Not Found",
      show: "%{name} #%{id}",
      empty: "No %{name} yet.",
      invite: "Do you want to add one?",
    },
    input: {
      file: {
        upload_several: "Drop some files to upload, or click to select one.",
        upload_single: "Drop a file to upload, or click to select it.",
      },
      image: {
        upload_several: "Drop some pictures to upload, or click to select one.",
        upload_single: "Drop a picture to upload, or click to select it.",
      },
      references: {
        all_missing: "Unable to find references data.",
        many_missing:
          "At least one of the associated references no longer appears to be available.",
        single_missing:
          "Associated reference no longer appears to be available.",
      },
      password: {
        toggle_visible: "Hide password",
        toggle_hidden: "Show password",
      },
    },
    message: {
      about: "About",
      are_you_sure: "Are you sure?",
      bulk_delete_content:
        "Are you sure you want to delete this %{name}? |||| Are you sure you want to delete these %{smart_count} items?",
      bulk_delete_title: "Delete %{name} |||| Delete %{smart_count} %{name}",
      bulk_update_content:
        "Are you sure you want to update this %{name}? |||| Are you sure you want to update these %{smart_count} items?",
      bulk_update_title: "Update %{name} |||| Update %{smart_count} %{name}",
      delete_content: "Are you sure you want to delete this item?",
      delete_title: "Delete %{name} #%{id}",
      details: "Details",
      error: "A client error occurred and your request couldn't be completed.",
      invalid_form: "The form is not valid. Please check for errors",
      loading: "The page is loading, just a moment please",
      no: "No",
      not_found: "Either you typed a wrong URL, or you followed a bad link.",
      yes: "Yes",
      unsaved_changes:
        "Some of your changes weren't saved. Are you sure you want to ignore them?",
    },
    navigation: {
      no_results: "No results found",
      no_more_results:
        "The page number %{page} is out of boundaries. Try the previous page.",
      page_out_of_boundaries: "Page number %{page} out of boundaries",
      page_out_from_end: "Cannot go after last page",
      page_out_from_begin: "Cannot go before page 1",
      page_range_info: "%{offsetBegin}-%{offsetEnd} of %{total}",
      page_rows_per_page: "Rows per page:",
      next: "Next",
      prev: "Prev",
      skip_nav: "Skip to content",
    },
    sort: {
      sort_by: "Sort by %{field} %{order}",
      ASC: "ascending",
      DESC: "descending",
    },
    profile: {
      my_profile: "My profile",
    },
    auth: {
      auth_check_error: "Please login to continue",
      user_menu: "Profile",
      username: "Username",
      password: "Password",
      sign_in: "Sign in",
      sign_in_error: "Authentication failed, please retry",
      logout: "Logout",
    },
    notification: {
      updated: "Element updated |||| %{smart_count} elements updated",
      created: "Element created",
      deleted: "Element deleted |||| %{smart_count} elements deleted",
      bad_item: "Incorrect element",
      item_doesnt_exist: "Element does not exist",
      http_error: "Server communication error",
      data_provider_error: "dataProvider error. Check the console for details.",
      i18n_error: "Cannot load the translations for the specified language",
      canceled: "Action cancelled",
      logged_out: "Your session has ended, please reconnect.",
    },
    validation: {
      required: "Required",
      minLength: "Must be %{min} characters at least",
      maxLength: "Must be %{max} characters or less",
      minValue: "Must be at least %{min}",
      maxValue: "Must be %{max} or less",
      password: "These password must be the same",
      passwordLength: "The password must be more than 8 characters",
      number: "Must be a number",
      companyName: "There is already a company with this name",
      emailPresent: 'This email is already present in the system',
      companyVat: "There is already a company with this vat number",
      email: "Must be a valid email",
      oneOf: "Must be one of: %{options}",
      regex: "Must match a specific format (regexp): %{pattern}",
    },
  },
};

// italian messages

const italianMessages = {
  ra: {
    nopermission: "Non si dispongono i privilegi per questa pagina",
    empty: "Nessun elemento disponibile",
    entity: {
      users: "Utenti",
      coffeemachine: "Macchine del caffe",
      events: "Eventi",
      companies: "Compagnie",
      transaction: "Transazioni",
      credits: "Crediti",
      maintenance: "Manutenzioni"
    },
    registration: {
      submit: "Registrazione",
      name: "Nome",
      surname: "Cognome",
      users: "Utenti",
      confirm_password: "Conferma password",
    },
    users: {
      producer: "Produttore",
      admin: "Amministratore",
      technician: "Tecnico",
      user: "Utente",
    },
    login: {
      forgot: "Password dimenticata?",
    },
    forgot: {
      title: "Reset password",
      disclaimer: "Verrà inviata una mail con il link per resettare la password"
    },
    action: {
      add_filter: "Aggiungi filtro",
      add: "Aggiungi",
      back: "Torna indietro",
      bulk_actions:
        "1 elemento selezionato |||| %{smart_count} elementi selezionati",
      cancel: "Cancella",
      clear_input_value: "Pulisci valore",
      clone: "Clona",
      confirm: "Conferma",
      create: "Crea",
      create_item: "Crea %{item}",
      delete: "Elimina",
      edit: "Modifica",
      export: "Esporta",
      list: "Lista",
      refresh: "Aggiorna",
      remove_filter: "Rimuovi questo filtro",
      remove: "Rimuovi",
      save: "Salva",
      search: "Cerca",
      show: "Mostra",
      sort: "Ordina",
      undo: "Torna indietro",
      unselect: "Deseleziona",
      expand: "Espandi",
      close: "Chiudi",
      open_menu: "Apri menu",
      close_menu: "Chiudi menu",
      update: "Modifica",
    },
    boolean: {
      true: "Si",
      false: "No",
      null: " ",
    },
    page: {
      create: "Crea %{name}",
      dashboard: "Dashboard",
      edit: "%{name} #%{id}",
      error: "Qualcosa è andato storto",
      list: "%{name}",
      loading: "Caricamento",
      not_found: "Non trovato",
      show: "%{name} #%{id}",
      empty: "No %{name} .",
      invite: "Vuoi aggiungerne 1?",
    },
    input: {
      file: {
        upload_several:
          "Elimina alcuni file per uploadare o clicca per selezionarne 1.",
        upload_single:
          "Elimina un file per uploadare o clicca per selezionarla",
      },
      image: {
        upload_several:
          "Elimina alcune immagini per uploadare o clicca per selezionarne 1.",
        upload_single:
          "Elimina un immagine per uploadare o clicca per selezionarla",
      },
      references: {
        all_missing: "Impossibile trovare dati di riferimenti.",
        many_missing: "Almeno una delle referenza non è più visibile.",
        single_missing: "La referenza associata non è più visibile",
      },
      password: {
        toggle_visible: "Nascondi password",
        toggle_hidden: "Mostra password",
      },
    },
    message: {
      about: "About",
      are_you_sure: "Sei sicuro?",
      bulk_delete_content:
        "Sicuro di voler cancellare questo %{name}? |||| Sicuro di voler cancellare questi %{smart_count} items?",
      bulk_delete_title: "Elimina %{name} |||| Elimina %{smart_count} %{name}",
      bulk_update_content:
        "Sicuro di voler aggiornare questo %{name}? |||| Sicuro di voler aggiornare questi %{smart_count} items?",
      bulk_update_title:
        "Aggiorna %{name} |||| aggiorna %{smart_count} %{name}",
      delete_content: "Sicuro di voler cancellare questo elemento?",
      delete_title: "Cancella %{name} #%{id}",
      details: "Dettagli",
      error: "Errore sul client la richiesta non è stata completata.",
      invalid_form: "Il form non è valido controlla gli errori",
      loading: "La pagina sta caricando. Un attimo perfavore",
      no: "No",
      not_found: "E' stato inserito un URL errato.",
      yes: "Si",
      unsaved_changes:
        "Alcune modifiche non sono state salvato, sicuro di volerle ignorare?",
    },
    navigation: {
      no_results: "Nessun risultato trovato",
      no_more_results:
        "La pagina numero %{page} è fuori dai limiti. Prova la precedente.",
      page_out_of_boundaries: "La pagina numero %{page} è fuori dai limiti",
      page_out_from_end: "Impossibile andare dopo l'ultima pagina",
      page_out_from_begin: "Impossibile andare prima della prima pagina",
      page_range_info: "%{offsetBegin}-%{offsetEnd} di %{total}",
      page_rows_per_page: "Righe per pagina:",
      next: "Prossimo",
      prev: "Precedente",
      skip_nav: "Avanza al contenuto",
    },
    sort: {
      sort_by: "Ordina per %{field} %{order}",
      ASC: "crescente",
      DESC: "decrescente",
    },
    auth: {
      auth_check_error: "Perfavore effettuare il login per proseguire",
      user_menu: "Profilo",
      username: "Username",
      password: "Password",
      sign_in: "Inserire credenziali",
      sign_in_error: "Autenticazione fallita, perfavore riprovare",
      logout: "Esci",
    },
    profile: {
      my_profile: "Mio profilo",
    },
    notification: {
      updated: "Elemento aggiornato |||| %{smart_count} elementi aggiornati",
      created: "Elemento creato",
      deleted: "Elemento cancellato |||| %{smart_count} elementi cancellati",
      bad_item: "Elemento errato",
      item_doesnt_exist: "Elemento inesistente",
      http_error: "Errore di comunicazione con il server",
      data_provider_error: "Errore del data provider. Controlla la console",
      i18n_error: "Impossibile caricare il linguaggio impostato",
      canceled: "Azione cancellata",
      logged_out: "Sessione terminata, perfavore riconnettersi.",
    },
    validation: {
      required: "Campo obbligatorio",
      minLength: "Deve essere %{min} caratteri o piu",
      maxLength: "Deve essere %{min} caratteri o meno",
      minValue: "Deve essere almeno %{min}",
      maxValue: "Deve essere %{max} massimo",
      password: "Le password devono essere uguali",
      companyName: "Esiste già una compagnia con questo nome",
      emailPresent: 'Esiste già questa mail nel sistema',
      companyVat: "Esiste già una compagnia con questa partita iva",
      passwordLength: "La password deve essere più lunga di 8 caratteri",
      number: "Deve essere un numero",
      email: "Deve essere una email valida",
      oneOf: "Deve essere uno di: %{options}",
      regex: "Deve essere in questo formato (regexp): %{pattern}",
    },
    user_field: {
      name: "Nome",
      surname: "Cognome",
      email: "Email",
      admin_level: "Livello amministratore",
      credit: "Credito",
    },
    company_field: {
      entity: "Compagnia",
      name: "Nome",
      address: "Indirizzo",
      city: "Città",
      vat: "Partita IVA",
      user: "Utente"
    },
    coffeemachine_field: {
      serial: "Seriale",
      sell_doc: "Documento vendita",
      warranty_date: "Data garanzia",
      temperature: "Temperatura",
      erogation_number: "Numero erogazioni",
      drawer_limit: "Limite cassetto",
      worn_capsules: "Capsule cassetto",
      descaling_limit: "Limite decalcificazione",
      manager: "Gestore",
      customer: "Cliente",
      erogation_default: "Erogazione default",
      eco_mode: "Modalita eco",
      credit: "Crediti",
      document: "Documento",
      title_document: "Documento",
      last_update_date: "Data modifica",
      creation_date: "Data creazione",
      size_short: "Corto",
      size_medium: "Medio",
      size_long: "Lungo",
      entity: "Macchina del caffe"
    },
    event_field: {
      serial: "Seriale",
      code: "Codice",
      event_date: "Data evento",
      entity: "Evento"
    },
    transaction_field: {
      sender: "Mittente",
      manager: "Gestore",
      user_receiver: "Utente ricevente",
      machine_receiver: "Macchina ricevente",
      quantity: "Quantita",
      date: "Data",
    },
    maintenance_field: {
      serial: "Seriale",
      sweeter_value: "Valore addolcitore",
      sweeter_check: "Controllo addolcitore",
      descaling_value: "Valore decalcificatore",
      descaling_check: "Controllo decalcificatore",
      date: "Data",
    },
    dashboard_field: {  
      cloud_admin: "Amministrazione cloud",
      welcome: "Benvenuto alla pagina amministrativa di Caffe25"
    },
  },
};

const messages = {
  en: englishMessages,
  it: italianMessages
}

const i18nProvider = polyglotI18nProvider(
  locale => messages[locale] ? messages[locale] : messages.en,
  resolveBrowserLocale()
);

export default i18nProvider;
