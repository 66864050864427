import {
  TextInput,
  Filter,
  useTranslate
} from "react-admin";

const UsersFilters = (props) => {

  const translate = useTranslate();

  return (
    <Filter {...props}>
      <TextInput label={translate("ra.user_field.name")} source="name" />
      <TextInput label={translate("ra.user_field.surname")} source="surname" />
      <TextInput label={translate("ra.user_field.email")} source="email" />
    </Filter>
  );
};

export default UsersFilters;
