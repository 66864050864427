import React, { useEffect } from "react";
import Utils from "../services/Utils";
import ApiCalls from "../services/ApiCalls";
import { useLogin, useTranslate, useNotify, useRedirect } from "react-admin";
import {
  Paper,
  withStyles,
  Grid,
  TextField,
  Button,
  Typography,
} from "@material-ui/core";
import { ArrowBackIos } from "@material-ui/icons";
import { Field, Form } from "react-final-form";
import { Email } from "@material-ui/icons";
const styles = (theme) => ({
  container: {
    display: "flex",
    height: "100vh",
    justifyContent: "center",
    backgroundColor: "#002e35",
  },
  margin: {
    margin: theme.spacing.unit * 2,
    marginTop: "3vh",
    marginRight: "3vw",
  },
  padding: {
    marginTop: "10vw",
    padding: theme.spacing.unit,
    width: "80vw",
    height: "50vh",
  },
  errorStyle: {
    color: "red",
    fontSize: "0.9em",
  },
});

const MyForgotPassword = (props) => {
  const { classes } = props;
  const login = useLogin();
  const translate = useTranslate();
  const notify = useNotify();
  const redirect = useRedirect();

  const submit = async (values) => {
    const validateRes = await validate(values);
    if (!Utils.isNotEmpty(validateRes)) {
      const result = await ApiCalls.forgotPassword(values);
    } else {
      return validateRes;
    }
  };

  const validate = (values) => {
    const errors = {};

    // email
    if (!values.email) {
      errors.email = translate("ra.validation.required");
    } else if (values.email) {
      if (Utils.validateEmail(values.email) === false) {
        errors.email = translate("ra.validation.email");
      }
    }

    return errors;
  };

  return (
    <Form
      onSubmit={submit}
      initialValues={{ email: "" }}
      render={({ handleSubmit }) => {
        const backSubmit = () => {
          redirect("/login");
        };

        return (
          <div className={classes.container}>
            <Paper className={classes.padding}>
              <div className={classes.margin}>
                <Grid
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "2vh",
                  }}
                  item
                  md={true}
                  sm={true}
                  xs={true}
                >
                  <ArrowBackIos
                    style={{ marginLeft: "1vw" }}
                    onClick={backSubmit}
                  />
                  <Typography
                    align="center"
                    variant="h5"
                    style={{ color: "#3f51b5" }}
                  >
                    {translate('ra.forgot.title')}
                  </Typography>

                  <ArrowBackIos style={{ color: "white" }} />
                </Grid>
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={6} alignItems="center">
                    <Grid style={{ paddingBottom: "1vh" }} item>
                      <Email />
                    </Grid>
                    <Grid item md={true} sm={true} xs={true}>
                      <Field name="email">
                        {({ input, meta }) => (
                          <>
                            <TextField
                              {...input}
                              id="email"
                              label="Email"
                              type="email"
                              fullWidth
                              required
                            />
                            {meta.touched && meta.submitError && (
                              <span className={classes.errorStyle}>
                                {meta.submitError}
                              </span>
                            )}
                          </>
                        )}
                      </Field>
                    </Grid>
                  </Grid>
                  <Grid
                    style={{ marginTop: "3vh" }}
                    item
                    md={true}
                    sm={true}
                    xs={true}
                  >
                    <Typography
                      style={{ color: "#3f51b5", textAlign: "center" }}
                    >
                      {translate('ra.forgot.disclaimer')}
                    </Typography>
                  </Grid>
                  <Grid container justify="center" style={{ marginTop: "3vh" }}>
                    <Button
                      variant="outlined"
                      color="primary"
                      style={{ textTransform: "none" }}
                      type="submit"
                    >
                      Invia
                    </Button>
                  </Grid>
                </form>
              </div>
            </Paper>
          </div>
        );
      }}
    />
  );
};

export default withStyles(styles)(MyForgotPassword);
