import { TextInput, ReferenceInput, SelectInput, Filter, useTranslate } from "react-admin";

const CompanyFilters = (props) => {

  const translate = useTranslate();

  return (
    <Filter {...props}>
      <TextInput label={translate("ra.company_field.name")} source="name" />
      <TextInput label={translate("ra.company_field.address")} source="address" />
      <TextInput label={translate("ra.company_field.city")} source="city" />
      <TextInput label={translate("ra.company_field.vat")} source="vatNumber" />
      <ReferenceInput label={translate("ra.company_field.user")} source="idUser" reference="users">
        <SelectInput label={translate("ra.company_field.user")} optionText="email" source="idUser" />
      </ReferenceInput>
    </Filter>
  );
};

export default CompanyFilters;
