import React, {
  createContext,
  useState,
  useCallback,
  useMemo,
  useEffect,
  useContext,
} from "react";
import {
  TextInput,
  SimpleForm,
  required,
  useDataProvider,
  useNotify,
  SaveContextProvider,
  useGetIdentity,
  useTranslate,
} from "react-admin";
import BackButton from "../components/BackButton";
import ApiCalls from "../services/ApiCalls";

const ProfileContext = createContext();

export const ProfileProvider = ({ children }) => {
  return <ProfileContext.Provider>{children}</ProfileContext.Provider>;
};

export const useProfile = () => useContext(ProfileContext);

export const ProfileEdit = ({ staticContext, ...props }) => {
  const dataProvider = useDataProvider();
  const translate = useTranslate();
  const [myProfile, setMyProfile] = useState();
  const notify = useNotify();
  const [saving, setSaving] = useState();

  useEffect(async () => {
    const email = localStorage.getItem("email");
    if (email) {
      const myUser = await ApiCalls.getUserByEmail(email);
      setMyProfile(myUser);
    }
  }, []);

  const handleSave = useCallback(
    (values) => {
      setSaving(true);
      dataProvider.updateUserProfile(
        { data: values },
        {
          onSuccess: (data) => {
            setSaving(false);
            notify("Il tuo profilo e stato aggiornato", "info", {
              _: "Il tuo profilo e stato aggiornato",
            });
          },
          onFailure: () => {
            console.log("some errors");
            setSaving(false);
            notify(
              "Errore tecnico durante l'aggiornamento. Riprovare piu tardi",
              "warning",
              {
                _: "Errore tecnico durante l'aggiornamento. Riprovare piu tardi",
              }
            );
          },
        }
      );
    },
    [dataProvider, notify]
  );

  const saveContext = useMemo(
    () => ({
      save: handleSave,
      saving,
    }),
    [saving, handleSave]
  );

  return (
    <SaveContextProvider value={saveContext}>
      <SimpleForm save={handleSave}>
        <BackButton variant="outlined" color="secondary" />
        <TextInput
          initialValue={myProfile && myProfile.email ? myProfile.email : null}
          label={translate("ra.user_field.email")}
          source="email"
          validate={required()}
        />
        <TextInput
          initialValue={myProfile && myProfile.name ? myProfile.name : null}
          label={translate("ra.user_field.name")}
          source="name"
          validate={required()}
        />
        <TextInput
          initialValue={
            myProfile && myProfile.surname ? myProfile.surname : null
          }
          label={translate("ra.user_field.surname")}
          source="surname"
          validate={required()}
        />
      </SimpleForm>
    </SaveContextProvider>
  );
};
