import Utils from "./Utils";

export default {
  getUserByEmail: async (email) => {
    // check if email exist. If it exists => return object with email, name, surname
    if (!email) {
    } else {
      const request = new Request(
        Utils.selectEndpoint("users") + "/getUserByEmail/" + email,
        {
          method: "GET",
          headers: new Headers({ "Content-Type": "application/json" }),
        }
      );
      try {
        const callResult = await fetch(request);
        const json = await callResult.json();
        if (json) {
          const myUser = {
            id: json.id,
            email: json.email,
            name: json.name,
            surname: json.surname,
          };
          return myUser;
        }
      } catch (err) {}
    }
  },
  validateCompanyName: async (values, translate) => {
    const errors = {};

    // enterprise name
    if (!values.company) {
      errors.company = translate("ra.validation.required");
    } else {
      const company = values.company;
      const request = new Request(
        Utils.selectEndpoint("companies") + "/getCompanyName/" + company,
        {
          method: "GET",
          headers: new Headers({ "Content-Type": "application/json" }),
        }
      );
      try {
        const callResult = await fetch(request);
        const json = await callResult.json();
        if (json) {
          errors.company = translate("ra.validation.companyName");
        }
      } catch (err) {}
    }
    return errors;
  },
  validateEmailBackend: async (values, translate) => {
    const errors = {};

    if (values.email) {
      const email = values.email;
      const request = new Request(
        Utils.selectEndpoint("users") + "/getEmail/" + email,
        {
          method: "GET",
          headers: new Headers({ "Content-Type": "application/json" }),
        }
      );
      try {
        const callResult = await fetch(request);
        const json = await callResult.json();
        if (json) {
          errors.email = translate("ra.validation.emailPresent");
        }
      } catch (err) {}
    }
    console.log(errors);
    return errors;
  },
  createUser: async (data) => {
    if (data) {
      const body = {
        name: data.name,
        surname: data.surname,
        email: data.email,
        password: data.password,
      };
      console.log("createUser " + Utils.selectEndpoint("users"));
      const request = new Request(Utils.selectEndpoint("users"), {
        method: "POST",
        headers: new Headers({ "Content-Type": "application/json" }),
        body: JSON.stringify(body),
      });
      try {
        const callResult = await fetch(request);
        const json = await callResult.json();
        return json;
      } catch (err) {
        return err;
      }
    }
  },
  createCompany: async (data) => {
    if (data) {
      const body = {
        name: data.company,
        vatNumber: data.vat,
      };
      console.log("createCompany " + Utils.selectEndpoint("companies"));
      const request = new Request(Utils.selectEndpoint("companies"), {
        method: "POST",
        headers: new Headers({ "Content-Type": "application/json" }),
        body: JSON.stringify(body),
      });
      try {
        const callResult = await fetch(request);
        const json = await callResult.json();
        return json;
      } catch (err) {
        return err;
      }
    }
  },
  register: async (data) => {
    if (data) {
      const body = {
        name: data.name,
        surname: data.surname,
        email: data.email,
        password: data.password,
        adminLevel: data.adminLevel
        // enterprise: data.company,
        // vatNumber: data.vatNumber,
      };
      const request = new Request(
        process.env.REACT_APP_AUTH_URL + "/registerAdmin",
        {
          method: "POST",
          headers: new Headers({ "Content-Type": "application/json" }),
          body: JSON.stringify(body),
        }
      );
      try {
        const callResult = await fetch(request);
        const json = await callResult.json();
        return json;
      } catch (err) {
        return err;
      }
    }
  },
  registerMaintenance: async (data) => {
    if (data) {
      const body = {
        name: data.name,
        surname: data.surname,
        email: data.email,
        password: data.password,
        adminLevel: data.adminLevel
        // enterprise: data.company,
        // vatNumber: data.vatNumber,
      };
      const request = new Request(
        process.env.REACT_APP_AUTH_URL + "/registerMaintenance",
        {
          method: "POST",
          headers: new Headers({ "Content-Type": "application/json" }),
          body: JSON.stringify(body),
        }
      );
      try {
        const callResult = await fetch(request);
        const json = await callResult.json();
        return json;
      } catch (err) {
        return err;
      }
    }
  },
  forgotPassword: async (data) => {
    if (data) {
      const body = {
        email: data.email,
      };
      console.log("process env auth ur L : " + process.env.REACT_APP_AUTH_URL);
      const request = new Request(
        process.env.REACT_APP_AUTH_URL + "/forgot-password",
        {
          method: "POST",
          headers: new Headers({ "Content-Type": "application/json" }),
          body: JSON.stringify(body),
        }
      );
      try {
        const callResult = await fetch(request);
        const json = await callResult.json();
        return json;
      } catch (err) {
        return err;
      }
    }
  },
  isRegistered: async (token) => {
    if (token) {
      const request = new Request(
        process.env.REACT_APP_AUTH_URL + "/isRegistrationTokenEnabled/" + token,
        {
          method: "GET",
          headers: new Headers({ "Content-Type": "application/json" }),
        }
      );
      try {
        const callResult = await fetch(request);
        const json = await callResult.json();
        if (json.isActive === false && json.registrationToken === token) {
          return json;
        } else {
          throw false;
        }
      } catch (err) {
        return false;
      }
    } else {
      return false;
    }
  },
  isPasswordTokenEnabled: async (token) => {
    if (token) {
      const request = new Request(
        process.env.REACT_APP_AUTH_URL + "/isPasswordTokenEnabled/" + token,
        {
          method: "GET",
          headers: new Headers({ "Content-Type": "application/json" }),
        }
      );
      try {
        const callResult = await fetch(request);
        const json = await callResult.json();
        if (json.resetPasswordToken === token) {
          return json;
        } else {
          throw false;
        }
      } catch (err) {
        return false;
      }
    } else {
      return false;
    }
  },
  changePassword: async (data) => {
    if (data) {
      const body = {
        token: data.token,
        password: data.password,
        confirmPassword: data.confirmPassword,
      };
      const request = new Request(
        process.env.REACT_APP_AUTH_URL + "/changePassword",
        {
          method: "POST",
          headers: new Headers({ "Content-Type": "application/json" }),
          body: JSON.stringify(body),
        }
      );
      try {
        const callResult = await fetch(request);
        const json = await callResult.json();
        if (data.token === json.resetPasswordToken) {
          return json;
        } else {
          return false;
        }
      } catch (err) {
        return err;
      }
    }
  },
  updateUser: async (id, data) => {
    if (id && data) {
      console.log(" this is my data " + data.email);
      const body = {
        email: data.data.email,
        name: data.data.name,
        surname: data.data.surname,
      };
      const request = new Request(process.env.REACT_APP_USERS_URL + "/" + id, {
        method: "PATCH",
        headers: new Headers({ "Content-Type": "application/json" }),
        body: JSON.stringify(body),
      });
      try {
        const callResult = await fetch(request);
        const json = await callResult.json();
        return json;
      } catch (err) {
        return err;
      }
    }
  },
};
