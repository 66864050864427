import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  usePermissions,
  BooleanField,
  DateField,
  NumberField,
  useTranslate
} from "react-admin";
import { adminType } from "../../enum/adminType";
import { Empty } from "../Empty";
import { NoPermission } from "../NoPermission";
import MaintenanceActions from "./actions/MaintenanceActions";
import MaintenanceFilters from "./filters/MaintenanceFilters";

export const MaintenanceList = (props) => {
  const {permissions} = usePermissions();
  const translate = useTranslate();


    return (
      <>
      {permissions === adminType.technician || permissions === adminType.administrator ?  
        <List empty={<Empty/>} perPage={10} actions={<MaintenanceActions/>} filters={<MaintenanceFilters />} {...props}>
          <Datagrid>
            <TextField label={translate("ra.maintenance_field.serial")} source="serial" />
            <NumberField label={translate("ra.maintenance_field.descaling_value")} source="decalcNumber" />
            <BooleanField label={translate("ra.maintenance_field.descaling_check")} source="isDoneDecalcification" />
            <NumberField label={translate("ra.maintenance_field.sweeter_value")} source="sweeterNumber" />
            <BooleanField label={translate("ra.maintenance_field.sweeter_check")} source="isDoneSweeter" />
            <DateField label={translate("ra.maintenance_field.date")} source="date" />
          </Datagrid>
        </List> : <NoPermission/>
      }
    </>
  );
}

// helpers

const MaintenanceTitle = ({ record }) => {
  return <span>Manutenzione</span>;
};
