// in authProvider.js

import { HttpError } from "react-admin";

const authProvider = {
  login: ({ email, password }) => {
    console.log("process env auth ur L : " + JSON.stringify(process.env));
    const request = new Request(
      process.env.REACT_APP_AUTH_URL + process.env.REACT_APP_LOGIN,
      {
        method: "POST",
        body: JSON.stringify({ email, password }),
        headers: new Headers({ "Content-Type": "application/json" }),
      }
    );
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((token) => {
        if (token && token.accessToken && token.refreshToken) {
          const adminLevel = Number(token.adminLevel)
          localStorage.setItem('accessToken',token.accessToken);
          localStorage.setItem('refreshToken',token.refreshToken);
          localStorage.setItem('email', token.email);
          localStorage.setItem('adminLevel', adminLevel);
          localStorage.setItem('id', token.id);
        }
      }).catch((err) => {
        return Promise.reject();
      });
  },
  logout: () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('adminLevel');
    localStorage.removeItem('email');
    localStorage.removeItem('id');
    return Promise.resolve();
  },

  checkAuth: () => {
    const accessToken = localStorage.getItem('accessToken');
    return localStorage.getItem('accessToken') ? Promise.resolve() : Promise.reject();
  },

  checkError: (error) => {
    const status = error.status;
    if (status === 401) {
      return Promise.reject();
    }
    return Promise.resolve();
  },

  getPermissions: () => {
    const role = Number(localStorage.getItem('adminLevel'))
    console.log(" role is " + role);
    return role? Promise.resolve(role) : Promise.reject()
  },
};

export default authProvider;
