import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  DateField,
  DateInput,
  Edit,
  Create,
  FileInput,
  SimpleForm,
  FileField,
  ReferenceField,
  Responsive,
  FunctionField,
  SelectInput,
  ReferenceInput,
  usePermissions,
  useTranslate,
  NumberInput
} from "react-admin";
import BackButton from "../../components/BackButton";
import { adminType } from "../../enum/adminType";
import { ecoType } from "../../enum/ecoType";
import { coffeeLength } from "../../enum/erogationType";
import { Empty } from "../Empty";
import CoffeemachineFilters from "./filters/CoffeemachineFilters";
import CoffeemachineCreateToolbar from "./toolbars/CoffeemachineCreateToolbar";
import {FcDownload} from "react-icons/fc" 

export const CoffeemachineList = (props) => {
  const {permissions} = usePermissions();
  const translate = useTranslate();

  return (
  <List empty={<Empty/>} perPage={10} filters={<CoffeemachineFilters />} {...props}>
    <Responsive 
      medium={
        <Datagrid rowClick={permissions === adminType.producer || permissions === adminType.administrator ? "edit" : ""}>
      <TextField label={translate("ra.coffeemachine_field.serial")} source="serial" />
      <FunctionField
        label={translate("ra.coffeemachine_field.sell_doc")}
        source="document"
        render={(record) => {
          if (record && record.document ) {
            return (
              <a href={"https://cloud.coffeebox.it/media/" + record.document}>
                <FcDownload size={23}/>
              </a>
            );
          }
        }}
      />
      <DateField label={translate("ra.coffeemachine_field.warranty_date")} source="warrantyDate" />
      <NumberField label={translate("ra.coffeemachine_field.temperature")} source="workingTemperature" />
      <NumberField label={translate("ra.coffeemachine_field.erogation_number")} source="totalDispensed" />
      <NumberField label={translate("ra.coffeemachine_field.drawer_limit")} source="drawerSize" />
      <NumberField label={translate("ra.coffeemachine_field.worn_capsules")} source="wornCapsules" />
      <NumberField label={translate("ra.coffeemachine_field.descaling_limit")} source="descalingInterval" />
      <ReferenceField label={translate("ra.coffeemachine_field.manager")} source="idUser" reference="users">
        <TextField source="email"/>
      </ReferenceField>
      <ReferenceField label={translate("ra.coffeemachine_field.customer")} source="idCompany" reference="companies">
        <TextField source="name"/>
      </ReferenceField>
      <FunctionField
        label={translate("ra.coffeemachine_field.erogation_default")}
        source="emissionTime"
        render={(record) => {
          if (record) {
            if (record.emissionTime == coffeeLength.short) {
              return translate("ra.coffeemachine_field.size_short");
            }
            if (record.emissionTime == coffeeLength.medium) {
              return translate("ra.coffeemachine_field.size_medium");
            }
            if (record.emissionTime == coffeeLength.long) {
              return translate("ra.coffeemachine_field.size_long");
            }
          }
        }}
      />
      <FunctionField
        label="Modalita eco"
        source="ecoMode"
        render={(record) => {
          if (record) {
            if (record.ecoMode == ecoType.supereco) {
              return "Super eco";
            }
            if (record.ecoMode == ecoType.boost) {
              return "Boost";
            }
            if (record.ecoMode == ecoType.eco) {
              return "Eco";
            }
          }
        }}
      />
      <NumberField minimum={0} label={translate("ra.coffeemachine_field.credit")} source="credits" />
      <DateField label={translate("ra.coffeemachine_field.last_update_date")} source="updateDatetime" />
      <DateField label={translate("ra.coffeemachine_field.creation_date")} source="registrationDate" />
    </Datagrid>
      }
    />
  </List>
  );
}

export const CoffeemachineEdit = (props) => {
  const {permissions} = usePermissions();
  const translate = useTranslate();
  return (
    <>
    <Edit title={<CoffeemachineTitle></CoffeemachineTitle>} {...props}>
      <SimpleForm>
        <BackButton variant="outlined" color="secondary" />
        {permissions === adminType.producer && 
          <DateInput label={translate("ra.coffeemachine_field.warranty_date")}  source="warrantyDate" />
        }
        {permissions === adminType.administrator &&
          <>
            <FileInput source="document" label={translate("ra.coffeemachine_field.document")}>
              <FileField source="document" title={translate("ra.coffeemachine_field.title_document")} />
            </FileInput>
            <ReferenceInput label={translate("ra.coffeemachine_field.customer")} source="idCompany" reference="companies">
              <SelectInput source="name"/>
            </ReferenceInput> 
            <br></br>
            <NumberInput min={0} label={translate("ra.coffeemachine_field.credit")} source="credit" />
            <br></br>
            <NumberInput min={0} label={translate("ra.coffeemachine_field.temperature")} source="temperature" /> 
          </>
        }
      </SimpleForm>
    </Edit>
    </>
  );
}

export const CoffeemachineCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm toolbar={<CoffeemachineCreateToolbar />}>
        <BackButton variant="outlined" color="secondary" />
      </SimpleForm>
    </Create>
  );
};

// helpers
const CoffeemachineTitle = ({ record }) => {

  const translate = useTranslate();

  return <span>{translate("ra.coffeemachine_field.entity")} {record ? `"${record.serial}"` : ""}</span>;
};
