import * as React from "react";
import {
  useNotify,
  useRedirect,
  Create,
  SimpleForm,
  SaveButton,
  Toolbar,
} from "react-admin";

const UserCreateToolbar = (props) => {
  const redirect = useRedirect();

  const onSuccess = () => {
    redirect("list", props.basePath);
  };

  return (
    <Toolbar {...props}>
      <SaveButton
        onSuccess={onSuccess}
        label="ra.action.save"
        redirect={true}
        submitOnEnter={true}
      />
    </Toolbar>
  );
};

export default UserCreateToolbar;
