import React, { useEffect, useState } from "react";
import ApiCalls from "../services/ApiCalls";
import { useTranslate, useRedirect } from "react-admin";
import { useParams } from "react-router-dom";
import {
  Paper,
  withStyles,
  Grid,
  TextField,
  Button,
  Typography,
} from "@material-ui/core";
import { ArrowBackIos } from "@material-ui/icons";
import { Field, Form } from "react-final-form";
import { Email } from "@material-ui/icons";
const styles = (theme) => ({
  container: {
    display: "flex",
    height: "100vh",
    justifyContent: "center",
    backgroundColor: "#002e35",
  },
  margin: {
    margin: theme.spacing.unit * 2,
    marginTop: "3vh",
    marginRight: "3vw",
  },
  padding: {
    marginTop: "10vw",
    padding: theme.spacing.unit,
    width: "80vw",
    height: "50vh",
  },
  errorStyle: {
    color: "red",
    fontSize: "0.9em",
  },
});

const MyRegisteredPage = (props) => {
  const { classes } = props;
  const [isRegistered, setisRegistered] = useState(false);
  const translate = useTranslate();
  const redirect = useRedirect();
  const { token } = useParams();

  useEffect(async () => {
    const didSignup = await ApiCalls.isRegistered(token);
    setisRegistered(didSignup);
  }, []);

  const backSubmit = () => {
    redirect("/login");
  };

  function isSignupOk() {
    if (isRegistered) {
      return "Il processo di registrazione è stato completato, è ora possibile loggarsi nel sistema";
    } else {
      return "Il processo di registrazione non è andato a buon fine, perchè il token utilizzato è scaduto o invalido";
    }
  }

  return (
    <div className={classes.container}>
      <Paper className={classes.padding}>
        <div className={classes.margin}>
          <Grid
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "2vh",
            }}
            item
            md={true}
            sm={true}
            xs={true}
          >
            <ArrowBackIos style={{ marginLeft: "1vw" }} onClick={backSubmit} />
            <Typography
              align="center"
              variant="h5"
              style={{ color: "#3f51b5" }}
            >
              Registrazione completa
            </Typography>

            <ArrowBackIos style={{ color: "white" }} />
          </Grid>
          <Grid style={{ marginTop: "3vh" }} item md={true} sm={true} xs={true}>
            <Typography style={{ color: "#3f51b5", textAlign: "center" }}>
              {isSignupOk()}
            </Typography>
          </Grid>
        </div>
      </Paper>
    </div>
  );
};

export default withStyles(styles)(MyRegisteredPage);
