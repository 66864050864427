import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Notification, useAuthProvider, useLogin, useNotify, useRedirect, useTranslate } from "react-admin";
import {
  Paper,
  withStyles,
  Grid,
  TextField,
  Button,
  FormControlLabel,
  Link,
  Checkbox,
} from "@material-ui/core";
import { Field, Form } from "react-final-form";
import { Fingerprint, Email } from "@material-ui/icons";
import Utils from "../services/Utils";

const styles = (theme) => ({
  container: {
    display: "flex",
    height: "100vh",
    justifyContent: "center",
    backgroundColor: "#002e35",
  },
  margin: {
    margin: theme.spacing.unit * 2,
    marginTop: "3vh",
    marginRight: "3vw",
  },
  padding: {
    marginTop: "10vw",
    padding: theme.spacing.unit,
    width: "80vw",
    height: "50vh",
  },
  errorStyle: {
    color: "red",
    fontSize: "0.9em",
  },
});

const Login = (props) => {
  const { classes } = props;
  const login = useLogin();
  const authProvider = useAuthProvider();
  const notify = useNotify();
  const translate = useTranslate();
  const redirect = useRedirect();

  const submit = (values) => {
    if (values && values.email && values.password) {
      const email = values.email;
      const password = values.password;
      login({ email, password }).catch(() =>
        notify(`Login Error`, 'error')
      );
    }
  };

  const validate = (values) => {
    const errors = {};

    // email
    if (!values.email) {
      errors.email = translate("ra.validation.required");
    } else if (values.email) {
      if (Utils.validateEmail(values.email) === false) {
        errors.email = translate("ra.validation.email");
      }
    }

    // password
    if (!values.password) {
      errors.password = translate("ra.validation.required");
    } else if (values.password.length < 8) {
      errors.password = translate("ra.validation.passwordLength");
    }

    return errors;
  };

  return (
    <>
    <Form
      onSubmit={submit}
      initialValues={{ email: "", password: "" }}
      validate={validate}
      render={({ handleSubmit }) => {
        const submitSignup = (e) => {
          e.preventDefault();
          redirect("/registration");
        };

        const submitForgotPassword = (e) => {
          e.preventDefault();
          console.log("forgot password");
          redirect("/forgot-password");
        };
        return (
          <div className={classes.container}>
            <Paper className={classes.padding}>
              <div className={classes.margin}>
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={6} alignItems="center">
                    <Grid style={{ paddingBottom: "1.5vh" }} item>
                      <Email />
                    </Grid>
                    <Grid item md={true} sm={true} xs={true}>
                      <Field name="email">
                        {({ input, meta }) => (
                          <div>
                            <TextField
                              {...input}
                              id="email"
                              label="Email"
                              type="email"
                              fullWidth
                              required
                            />
                            {meta.touched &&
                              (meta.error || meta.submitError) && (
                                <span className={classes.errorStyle}>
                                  {meta.error || meta.submitError}
                                </span>
                              )}
                          </div>
                        )}
                      </Field>
                    </Grid>
                  </Grid>
                  <Grid container spacing={6} alignItems="center">
                    <Grid style={{ paddingBottom: "1.5vh" }} item>
                      <Fingerprint />
                    </Grid>
                    <Grid item md={true} sm={true} xs={true}>
                      <Field name="password">
                        {({ input, meta }) => (
                          <>
                            <TextField
                              {...input}
                              id="password"
                              label="Password"
                              type="password"
                              fullWidth
                              required
                            />
                            {meta.touched &&
                              (meta.error || meta.submitError) && (
                                <span className={classes.errorStyle}>
                                  {meta.error || meta.submitError}
                                </span>
                              )}
                          </>
                        )}
                      </Field>
                    </Grid>
                  </Grid>
                  <Grid
                    style={{ marginTop: "2vh" }}
                    container
                    alignItems="center"
                    justify="space-between"
                  >
                    <Grid style={{ marginTop: "1vh" }} item>
                      <Link
                        href="#"
                        style={{ fontSize: "0.9em" }}
                        onClick={submitForgotPassword}
                      >
                        {translate('ra.login.forgot')}
                      </Link>
                    </Grid>
                    {/* <Grid style={{ marginTop: "1vh" }} item>
                      <Link
                        href="#"
                        style={{ fontSize: "0.9em" }}
                        onClick={submitSignup}
                      >
                        Registrazione
                      </Link>
                    </Grid> */}
                  </Grid>
                  <Grid container justify="center" style={{ marginTop: "3vh" }}>
                    <Button
                      variant="outlined"
                      color="primary"
                      style={{ textTransform: "none" }}
                      type="submit"
                    >
                      Login
                    </Button>
                  </Grid>
                </form>
              </div>
            </Paper>
          </div>
        );
      }}
    />
    <Notification/>
    </>
  );
};

export default withStyles(styles)(Login);
