import {
  Filter,
  ReferenceInput,
  SelectInput,
  useTranslate
} from "react-admin";

const TransactionFilters = (props) => {

  const translate = useTranslate();

  return (
    <Filter {...props}>
      <ReferenceInput label={translate("ra.transaction_field.sender")} source="sender" reference="users">
        <SelectInput label={translate("ra.transaction_field.sender")} source="sender" />
      </ReferenceInput>
      <ReferenceInput label={translate("ra.transaction_field.manager")} source="idAdmin" reference="users">
        <SelectInput label={translate("ra.transaction_field.manager")} source="idAdmin" />
      </ReferenceInput>
    </Filter>
  );
};

export default TransactionFilters;
