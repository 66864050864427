
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { CreateButton, List, useListContext, useTranslate } from 'react-admin'



export const NoPermission = () => {
    const { basePath, resource } = useListContext();
    const translate = useTranslate();
    
    return (
        <Box textAlign="center" m={1}>
            <Typography style={{marginTop: '5%', color: "#808080"}} paragraph>
                {translate("ra.nopermission")}
            </Typography>
        </Box>
    );
};