export const nfcError = [
    { id: -4096, name: "GENERICO"},
    { id: -4095, name: "DATALENGTH"},
    { id: -4094, name: "RANDOMNUMBER"},
    { id: -4093, name: "FRAMENUMBER"},
    { id: -4092, name: "FRAMETYPE"},
    { id: -4091, name: "TIMEOUT"},
    { id: -4090, name: "MESSAGETYPE"},
    { id: -4080, name: "MEMORY"},
    { id: -4079, name: "HEATERFAULT"},
    { id: -4078, name: "DISPENSING"},
    { id: -4077, name: "CAPSULE"},
    { id: -4076, name: "CREDIT"},
]