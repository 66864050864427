import { TextInput, Filter, ReferenceInput, SelectInput, useTranslate } from "react-admin";

const CoffeemachineFilters = (props) => {

  const translate = useTranslate();

  return (
    <Filter {...props}>
      <ReferenceInput label={translate("ra.coffeemachine_field.manager")} source="idUser" reference="users">
        <SelectInput label={translate("ra.coffeemachine_field.manager")} optionText="email" source="idUser" />
      </ReferenceInput>
      <ReferenceInput label={translate("ra.coffeemachine_field.customer")} source="idCompany" reference="companies">
        <SelectInput label={translate("ra.coffeemachine_field.customer")} optionText="name" source="idUser" />
      </ReferenceInput>
    </Filter>
  );
};

export default CoffeemachineFilters;
